import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCarousel from 'vue-carousel';
import VueTailwind from 'vue-tailwind'
import './assets/tailwind.css'
import i18n from './i18n'
import loader from '@/components/loader'
//import LoadScript from 'vue-plugin-load-script';
//Vue.use(LoadScript);
import VueLoading from 'vuejs-loading-plugin'
import VueToastify from "vue-toastify";
import './firebase';
import { firestorePlugin } from 'vuefire';
import VueSocialSharing from 'vue-social-sharing'
import VueMeta from 'vue-meta'
Vue.use(VueMeta);
Vue.use(VueSocialSharing);
Vue.use(firestorePlugin);

Vue.use(VueToastify,{
  position: 'center-center',
  backdrop: 'rgba(255,255,255,0.8)'
});


// overwrite defaults
Vue.use(VueLoading, {
  
  loading: false, // default false
  customLoader: loader, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  
})
Vue.use(VueCarousel);
Vue.config.productionTip = false
Vue.use(VueTailwind);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created(){
    this.$store.dispatch('loadPosts');
    this.$store.dispatch('loadBlogs');
    this.$store.dispatch('loadStaffs');
    this.$store.dispatch('setLocale');
  }
}).$mount('#app')
