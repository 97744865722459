<style scoped>
.VueCarousel-slide {
  visibility: visible;
}
.VueCarousel {
  width: 100%;
  height: 100%;
}
</style>
<template>
  <div>
    <div class="hidden sm:block" style="direction: ltr !important">
      <carousel
        :perPage="1"
        :paginationPosition="'bottom-overlay'"
        :navigationEnabled="true"
        navigationNextLabel="<i class='fa fa-arrow-right'></i>"
        navigationPrevLabel="<i class='fa fa-arrow-left'></i>"
      >
        <slide>
          <div class="oneSlide">
            <img src="/Images/the company.jpg" alt="The Company" />
            <div class="info hidden">
              <h3>{{ $t("Section.Company") }}</h3>
              <a href="#Company" class="siteBtn">{{ $t("Explore") }}</a>
            </div>
          </div>
        </slide>
        <slide>
          <div class="oneSlide">
            <img src="/Images/principles.jpg" alt="Principles" />
            <div class="info hidden">
              <h3>{{ $t("Section.PrinciplesTitle") }}</h3>
              <a href="#Principles" class="siteBtn">{{ $t("Explore") }}</a>
            </div>
          </div>
        </slide>
        <slide>
          <div class="oneSlide">
            <img src="/Images/why us.jpg" alt="Why Agora?" />
            <div class="info hidden">
              <h3>{{ $t("Section.WhyTitle") }}</h3>
              <a href="#Why" class="siteBtn">{{ $t("Explore") }}</a>
            </div>
          </div>
        </slide>
        <slide>
          <div class="oneSlide">
            <img src="/Images/contact.jpg" alt="Contact Us" />
            <div class="info hidden">
              <h3>{{ $t("Section.ContactTitle") }}</h3>
              <a href="#Contact" class="siteBtn">{{ $t("Explore") }}</a>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
    <div class="block sm:hidden" style="direction: ltr !important">
      <carousel
        :perPage="1"
        :paginationPosition="'bottom-overlay'"
        :navigationEnabled="true"
        navigationNextLabel="<i class='fa fa-arrow-right'></i>"
        navigationPrevLabel="<i class='fa fa-arrow-left'></i>"
      >
        <slide>
          <div class="oneSlide">
            <img src="/Images/mobile/the company.jpg" alt="The Company" />
            <div class="info hidden">
              <h3>{{ $t("Section.CompanyTitle") }}</h3>
              <a href="#Company" class="siteBtn">{{ $t("Explore") }}</a>
            </div>
          </div>
        </slide>
        <slide>
          <div class="oneSlide">
            <img src="/Images/mobile/principles.jpg" alt="Principles" />
            <div class="info hidden">
              <h3>{{ $t("Section.PrinciplesTitle") }}</h3>
              <a href="#Principles" class="siteBtn">{{ $t("Explore") }}</a>
            </div>
          </div>
        </slide>
        <slide>
          <div class="oneSlide">
            <img src="/Images/mobile/why us.jpg" alt="Why Agora?" />
            <div class="info hidden">
              <h3>{{ $t("Section.WhyTitle") }}</h3>
              <a href="#Why" class="siteBtn">{{ $t("Explore") }}</a>
            </div>
          </div>
        </slide>
        <slide>
          <div class="oneSlide">
            <img src="/Images/mobile/contact.jpg" alt="Contact Us" />
            <div class="info hidden">
              <h3>{{ $t("Section.ContactTitle") }}</h3>
              <a href="#Contact" class="siteBtn">{{ $t("Explore") }}</a>
            </div>
          </div>
        </slide>
      </carousel>
    </div>

    <div id="Company" class="Company">
      <div class="wrapper shadow-lg rounded-md my-2">
        <div>
          <h2 class="text-2xl font-bold mb-4">
            {{ $t("Section.CompanyTitle") }}
          </h2>
          <div v-html="$t('Section.CompanyText')"></div>
          <br />
          <h2 class="text-2xl font-bold mb-4">
            {{ $t("Section.MissionTitle") }}
          </h2>
          <div>
            <p>
              {{ $t("Section.MissionText") }}
            </p>
          </div>
          <br />
          <h2 class="text-2xl font-bold mb-4">
            {{ $t("Section.VisionTitle") }}
          </h2>
          <div>
            <p>
              {{ $t("Section.VisionText") }}
            </p>
          </div>
        </div>
        <div>
          <img src="/Images/3-01.png" alt="The Company" />
        </div>
      </div>
    </div>
    <div id="Project" class="Project">
      <div class="wrapper shadow-lg rounded-md my-2">
        <h2 class="text-2xl font-bold mb-4">
            {{ $t("Section.Project") }}
          </h2>

          <div class="lg:grid grid-cols-5">
            <div class="col-span-3"  v-html="$t('Section.ProjectText')" ></div>
            <div class="justify-self-center col-span-2 "><img src="/Images/books.png" style="width:400px" alt="Translated books" class="mx-auto pt-5 lg:pt-0"></div>
          </div>
      </div>
    </div>
    
    <div id="Why" class="Why">
      <div class="wrapper shadow-lg rounded-md my-5">
        <div>
          <img src="/Images/1-01.png" alt="Team " />
        </div>
        <div class="mt-5">
          <h2 class="text-2xl font-bold mb-4">
            {{ $t("Section.WhyTitle") }}
          </h2>
          <div v-html="$t('Section.WhyText')"></div>
        </div>
      </div>
    </div>

    
    <div id="Contact" class="Contact">
      <div class="wrapper shadow-lg rounded-md my-5">
        <div>
          <form @submit.prevent="sendEmail" ref="form" id="contactForm">
            <h2 class="text-2xl font-bold mb-2">
              {{ $t("Section.ContactTitle") }}
            </h2>

            <div class="aboveForm">
              <p>
                {{ $t("ContactForm.Heading") }}
                <br>
                <br>
                <span>{{ $t("ContactForm.subheading") }}</span>
                <br>
              </p>

              <div class="text-xs">
                <br>
                <p class="font-bold" v-html="$t('ContactForm.working')"> </p>
                <br />
                <h3 class="font-bold text-lg siteColor" v-html="$t('ContactForm.Iraq.name')"> </h3>
                <br>
                <span class="text-sm" v-html="$t('ContactForm.Iraq.address')"> </span>
                
                <br>
                <span class="text-sm font-bold">
                  <a href="tel:009647702000545">{{
                    $t("ContactForm.PhoneOne")
                  }}</a>
                  |
                  <a href="tel:009647502000545">{{
                    $t("ContactForm.PhoneTwo")
                  }}</a>
                </span>
                <br>
                <br>
                <h3 class="font-bold text-lg siteColor" v-html="$t('ContactForm.Canada.name')"> </h3>
                <br>
                <span class="text-sm" v-html="$t('ContactForm.Canada.address')"> </span>
                <br>
                <span class="text-sm font-bold">
                  <a href="tel:0017767781476">{{
                    $t("ContactForm.PhoneCanada")
                  }}</a>
                  
                </span>
                <br>
               
                
              </div>
            
              <br>
              <p>{{ $t("ContactForm.Sub") }}</p>
            </div>
            <label for="user_name"> {{ $t("ContactForm.Name") }} </label>
            <input
              required
              v-model="contact.name"
              type="text"
              name="user_name"
              class="
                block
                w-full
                h-8
                rounded
                shadow-lg
                my-2
                px-2
                outline-none
                transition
                hover:shadow-md
              "
            />
            <label for="user_email">{{ $t("ContactForm.Email") }} </label>
            <input
              required
              v-model="contact.mail"
              type="email"
              name="user_email"
              class="
                block
                w-full
                h-8
                rounded
                shadow-lg
                my-2
                px-2
                outline-none
                transition
                hover:shadow-md
              "
            />
            <label for="user_phone"> {{ $t("ContactForm.Phone") }} </label>
            <input
              required
              v-model="contact.phone"
              type="tel"
              name="user_phone"
              class="
                block
                w-full
                h-8
                rounded
                shadow-lg
                my-2
                px-2
                outline-none
                transition
                hover:shadow-md
              "
            />
            <label for="message" class="block">
              {{ $t("ContactForm.Message") }}
            </label>
            <textarea
              required
              v-model="contact.message"
              name="message"
              class="
                block
                w-full
                h-24
                rounded
                shadow-lg
                my-2
                px-2
                outline-none
                transition
                hover:shadow-md
              "
            ></textarea>
            <button
              class="
                mt-3
                block
                bg-blue-500
                hover:bg-blue-700
                text-white
                font-bold
                py-2
                px-4
                rounded
                transition
              "
            >
              {{ $t("Send") }}
            </button>
          </form>
        </div>
        <div class="w-50">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3245.5323177804235!2d45.432587515609875!3d35.56525094393079!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40002dc3c9af9dcb%3A0x1382709528a56a1e!2zQWdvcmEgVmlzaW9uINim2Kfar9uG2LHYpyDapNuM2pjZhg!5e0!3m2!1sen!2siq!4v1657790412965!5m2!1sen!2siq"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Service ID service_it8ziaq
//Template ID template_fro9gmk
//user ID user_Mhdpo0N7SP8VnDjdVXPda

import { Carousel, Slide } from "vue-carousel";
import emailjs from "@emailjs/browser";
import { mapGetters } from "vuex";
export default {
  computed: mapGetters([
    "getCurrentLocale",
    "getCurrentDir",
    "getPosts",
    "Lang",
  ]),
  name: "Home",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      filteredPosts: null,
      contact: {
        name: null,
        mail: null,
        phone: null,
        message: null,
      },
    };
  },
  methods: {
    sendEmail() {
      this.$loading(true);
      emailjs
        .sendForm(
          "service_it8ziaq",
          "template_fro9gmk",
          this.$refs.form,
          "user_Mhdpo0N7SP8VnDjdVXPda"
        )
        .then(
          (result) => {
            console.log(result);
            this.contact = {
              name: null,
              mail: null,
              phone: null,
              message: null,
            };
            this.$loading(false);
            this.$vToastify.success(
              this.getCurrentLocale == "ku"
                ? "ئیمەیڵ بە سەرکەوتوویی نێردرا."
                : "Email was sent successfully.",
              this.getCurrentLocale == "ku" ? "سەرکەوتن!" : "Success!"
            );
          },
          (error) => {
            console.log(error);
            this.$loading(false);
            this.$vToastify.error(
              this.getCurrentLocale == "ku"
                ? "تکایە پەیوەندی بکە بە سەرپەرشتیاری وێب سایتەوە."
                : "Please contact website administrator.",
              this.getCurrentLocale == "ku"
                ? "هەڵەیەک ڕوویدا."
                : "An error occurred."
            );
          }
        );
    },
  },
  mounted() {
    //this.$loading(true);

    let postFillInterval = setInterval(() => {
      this.filteredPosts = this.getPosts.filter((item) => {
        return item.Enabled;
      });
      if (this.filteredPosts.length > 0) {
        clearInterval(postFillInterval);
      } else {
        console.log("Retrieving posts . . .");
      }
      console.log("hit");
      this.$loading(false);
    }, 500);
  },
};
</script>
