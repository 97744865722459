<template>
  <div
    id="app"
    :data-route="$route.path.substring(1,10)"
    :class="getCurrentDir"
    :dir="getCurrentDir"
    :style="{
      direction: getCurrentDir,
      'text-align': getCurrentDir == 'rtl' ? 'right' : 'left',
    }"
  >
    <div class="menu" :class="{ toggled: toggled }">
      <ul>
        <li>
          <a @click="toggled = false" href="/#Company">{{
            $t("Section.CompanyTitle")
          }}</a>
        </li>
        <li>
          <a @click="toggled = false" href="/#Project">{{
            $t("Section.Project")
          }}</a>
        </li>
        <li>
          <a @click="toggled = false" href="/#Why">{{
            $t("Section.WhyTitle")
          }}</a>
        </li>
       
        <li>
          <a @click="toggled = false" href="/#Contact">{{
            $t("Section.ContactTitle")
          }}</a>
        </li>
      </ul>
    </div>
    <nav>
      <div class="logo grid font-bold" style="grid-template-columns: auto auto auto">
        <router-link to="/">
          <img src="/Images/logo-white.png" alt="Logo" />
        </router-link>
        <ul
          class="desktopNav text-white hidden gap-5 lg:flex items-center text-xs xl:text-base"
          :class="{
            'ml-5 pl-5': getCurrentDir == 'ltr',
            'mr-5 pr-5': getCurrentDir == 'rtl',
          }"
          :style="{ 'line-height': getCurrentDir == 'rtl' ? '36px' : '30px' }"
        >
          <li>
            <a @click="toggled = false" href="/#Company">{{
              $t("Section.CompanyTitle")
            }}</a>
          </li>
          <li>
            <a @click="toggled = false" href="/#Project">{{
              $t("Section.Project")
            }}</a>
          </li>
          <li>
            <a @click="toggled = false" href="/#Why">{{
              $t("Section.WhyTitle")
            }}</a>
          </li>
          
          <li>
            <a @click="toggled = false" href="/#Contact">{{
              $t("Section.ContactTitle")
            }}</a>
          </li>
        </ul>
      </div>
      <div class="grid font-bold" style="grid-template-columns: auto auto">
        <div
          class="flex h-full px-3 text-gray-100 uppercase items-center"
          
        >
          <span class="cursor-pointer">
            <a @click="switchLocale('kr')" :key="'kr'" style="font-family:'Speda' !important" >{{
              $t("Language.Kirmanji")
            }}</a>
          </span>
          <span class="px-3">|</span>
          <span class="cursor-pointer">
            <a @click="switchLocale('ku')" :key="'ku'" style="font-family:'Speda' !important" >{{
              $t("Language.Kurdish")
            }}</a>
          </span>
          <span class="px-3">|</span>
          <span class="cursor-pointer">
            <a @click="switchLocale('en')" :key="'en'" style="font-family:'Montserrat', sans-serif !important" >{{
              $t("Language.English")
            }}</a>
          </span>
        </div>

        <div
          class="hamburger block lg:hidden"
          :class="{ toggled: toggled }"
          @click="toggled = !toggled"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
    <sideBar />
    <router-view
      :class="getCurrentDir"
      :dir="getCurrentDir"
      :style="{
        direction: getCurrentDir,
        'text-align': getCurrentDir == 'rtl' ? 'right' : 'left',
      }"
    />

    <footer class="block">
      
      <div class="social mx-auto">
        <div class="">
          <a href="https://www.facebook.com/agoravisioncom" target="_blank"
            ><svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"/></svg></a>
          <a href="https://www.linkedin.com/company/agoravision/" target="_blank"
            ><svg fill="#fff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg></a>
          <a href="https://www.instagram.com/agoravisioncom/" target="_blank"
            ><svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"/></svg></a>
          <a
            href="https://www.youtube.com/channel/UCcGXMcvYue1apphdDW4jtZA/featured" target="_blank"
            ><svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M186.8 202.1l95.2 54.1-95.2 54.1V202.1zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-42 176.3s0-59.6-7.6-88.2c-4.2-15.8-16.5-28.2-32.2-32.4C337.9 128 224 128 224 128s-113.9 0-142.2 7.7c-15.7 4.2-28 16.6-32.2 32.4-7.6 28.5-7.6 88.2-7.6 88.2s0 59.6 7.6 88.2c4.2 15.8 16.5 27.7 32.2 31.9C110.1 384 224 384 224 384s113.9 0 142.2-7.7c15.7-4.2 28-16.1 32.2-31.9 7.6-28.5 7.6-88.1 7.6-88.1z"/></svg></a>
        </div>
      </div>
      <p class="text-center font-bold">
        <span v-html="$t('Copyright')"></span>
      </p>
    </footer>
    <div
      id="backToTop"
      class="rounded-md shadow-lg font-bold text-xl text-blue-500 text-center"
    >
      <a href="#app"><i class="fa fa-caret-up"></i></a>
    </div>
  </div>
</template>
<script>
import sideBar from './components/sidebar.vue'
import { mapGetters } from "vuex";
export default {
  computed: mapGetters(["getCurrentLocale", "getCurrentDir","getUser"]),
  components: {
    sideBar,
  },
  data() {
    return {
      toggled: false,
    };
  },
  
  mounted() {
    console.log(this.$store.getters.getCurrentLocale);
  },
  methods: {
    saveLocale() {
      sessionStorage.setItem("locale", this.$store.getters.getCurrentLocale);
    },
    switchLocale(newLocale) {
      this.$store.commit("changeLocale", newLocale);
      this.$i18n.locale = newLocale;
      this.toggled = false;
      document.querySelector("body").removeAttribute("class");
      document.querySelector("body").classList.add(this.getCurrentDir);
      setTimeout(() => {}, 300);
    },
  },
};
</script>
<style>
@media (max-width: 520px) {
  .grid.font-bold a {
    font-size: 12px;
  }
}
@media (max-width: 400px) {
  .grid.font-bold a {
    font-size: 10px;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
