import Vue from 'vue'
import Vuex from 'vuex'
import {app} from '../firebase'
import router from '../router'
import i18n from '../i18n'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentLocale: 'en',
    currentDir: 'ltr',
    currentLang:'EN',
    posts:[],
    blogs:[],
    staffs:[],
    About:null,
    user:null
  },
  getters: {
    getCurrentLocale: (state) => state.currentLocale,
    Lang: (state) => state.currentLang,
    getCurrentDir: (state) => state.currentDir,
    getPosts:(state)=>state.posts,
    getBlogs:(state)=>state.blogs,
    getStaffs:(state)=>state.staffs,
    getUser:(state)=>state.user,
    getAbout:(state)=>state.About
    
  }
  ,
  actions: {
    loadPosts({commit}){
      app.database().ref('data/Posts').once('value')
      .then((data)=>{
        const dataPosts =[] ;
        const obj = data.val();

        for (let key in obj){
          dataPosts.push({"id":key,...obj[key]})
        }
        dataPosts.sort((a, b) => {
          const newA = a.Date.split('/').reverse().join('-');
          const newB = b.Date.split('/').reverse().join('-');
          return +new Date(newA) - +new Date(newB)
        });
        dataPosts.reverse();
        commit('setPosts',dataPosts);
      })
      .catch(
        (error)=>{
          console.log(error)
        }
      );
      
    },
    loadBlogs({commit}){
      app.database().ref('data/Blogs').once('value')
      .then((data)=>{
        const dataPosts =[] ;
        const obj = data.val();

        for (let key in obj){
          dataPosts.push({"id":key,...obj[key]})
        }
        dataPosts.sort((a, b) => {
          const newA = a.Date.split('/').reverse().join('-');
          const newB = b.Date.split('/').reverse().join('-');
          return +new Date(newA) - +new Date(newB)
        });
        dataPosts.reverse();
        commit('setBlogs',dataPosts);
      })
      .catch(
        (error)=>{
          console.log(error)
        }
      );
      
    },
    loadStaffs({commit}){
      app.database().ref('data/Staffs').once('value')
      .then((data)=>{
        const dataPosts =[] ;
        const obj = data.val();

        for (let key in obj){
          dataPosts.push({"id":key,...obj[key]})
        }
        dataPosts.sort((a, b) => {
          const newA = a.Date.split('/').reverse().join('-');
          const newB = b.Date.split('/').reverse().join('-');
          return +new Date(newA) - +new Date(newB)
        });
        dataPosts.reverse();
        commit('setStaffs',dataPosts);
      })
      .catch(
        (error)=>{
          console.log(error)
        }
      );
      
    },
    setLocale({commit,state}){
      if(localStorage.getItem('locale')){
        commit('changeLocale',localStorage.getItem('locale'))
      }
      else{
        
        localStorage.setItem('locale',state.currentLocale);
        commit('changeLocale',localStorage.getItem('locale'))
      }
    },
    signUserIn({commit},payload) {
      app.auth().signInWithEmailAndPassword(payload.email,payload.password)
      .then(user=>{
        
        const newUser = {
          uid:user.user.multiFactor.user.uid,
          email:user.user.multiFactor.user.email
        }
        
        commit('setUser',newUser)
      }).catch(error=>{
        console.log(error)
      });

    },
    autoSignIn({commit},payload){
      
      commit('setUser',{id:payload.uid,email:payload.email})
    },
    logOut({commit})
    {
      app.auth().signOut();
      commit('setUser',null);
      location.reload();
    }
  },
  mutations: {
    setUser(state,newUser){
      state.user = newUser;
      router.push("/dashboard/blogs")
    },
    changeLocale(state, newLocale) {
      state.currentLocale = newLocale;
      i18n.locale = newLocale;
      state.currentDir = newLocale == 'ku' ? 'rtl' : 'ltr';
      state.currentLang = newLocale =='ku' ? 'KU' :  newLocale =='kr'?'KR':'EN';
      localStorage.setItem('locale',state.currentLocale);
    },
    
    addPost(state, newPost){
      app.database().ref('data/Posts').push(newPost);
      location.reload();
      
    },
    deletePost(state, id){
      app.database().ref('data/Posts').child(id).remove();
      location.reload();
      
    },
    updatePost(state,data){
      console.log(data)
      app.database().ref('data/Posts').child(data.id).set({...data.data});
      location.reload();
      
    },
    updateAbout(state,data){
      console.log(data)
      app.database().ref('data/About').child(data.id).set({...data.data});
      location.reload();
      
    },
    setPosts(state,payload){
      state.posts=payload;
      
    },

    
    addBlog(state, newPost){
      app.database().ref('data/Blogs').push(newPost);
      //location.reload();
      
    },
    deleteBlog(state, id){
      app.database().ref('data/Blogs').child(id).remove();
      location.reload();
      
    },
    updateBlog(state,data){
      console.log(data)
      app.database().ref('data/Blogs').child(data.id).set({...data.data});
      //location.reload();
      
    },
    setBlogs(state,payload){
      state.blogs=payload;
      
    },

    
    addStaff(state, newStaff){
      app.database().ref('data/Staffs').push(newStaff);
      //location.reload();
      
    },
    deleteStaff(state, id){
      app.database().ref('data/Staffs').child(id).remove();
      location.reload();
      
    },
    updateStaff(state,data){
      console.log(data)
      app.database().ref('data/Staffs').child(data.id).set({...data.data});
      //location.reload();
      
    },
    setStaffs(state,payload){
      state.staffs=payload;
      
    }
  }
})
